import {TranslationFunction} from 'i18next'
export {ExpandableRow} from './expandable-row'

export interface ExpandableRowProps {
  children: React.ReactNode
  details?: React.ReactNode
  isExpanded?: boolean
  toggle?: () => void
  mobile?: boolean
  arrowClass?: string
  href?: string
  navigable?: boolean
  divider?: boolean
  t: TranslationFunction
}
