import * as DateFormatter from '@wix/wix-events-commons-statics/dist/date/formatter'
import {getFormattedMoney} from '@wix/wix-events-commons-statics/dist/price/formatter'
import {PAST_EVENT_STATUSES} from '../config/constants'
import {DetailedEvent} from '../types/state'

export const isExpanded = (expandedEvents: string[], event: DetailedEvent): boolean =>
  expandedEvents.includes(getEventId(event))

export const getEventId = (event: DetailedEvent): string => event.event.id

export const getStartDate = (event: DetailedEvent): string => event.event.scheduling.config.startDate

export const isStartDateTbd = (event: DetailedEvent): boolean => event.event.scheduling.config.scheduleTbd

export const getFormattedStartDate = (event: DetailedEvent, locale: string, full: boolean = true): string => {
  const {startDate, scheduleTbd, scheduleTbdMessage, timeZoneId, showTimeZone} = event.event.scheduling.config
  if (scheduleTbd) {
    return scheduleTbdMessage
  }
  if (full) {
    return DateFormatter.getFullDate(startDate, timeZoneId, true, locale, showTimeZone)
  } else {
    return DateFormatter.getYearMonthDay(startDate, timeZoneId, locale)
  }
}

export const getFormattedLocation = (event: DetailedEvent) => event.event.location.name || event.event.location.address

export const getFormattedFullLocation = (event: DetailedEvent) => {
  const location = event.event.location
  if (location.address && location.name) {
    return `${location.name}, ${location.address}`
  } else {
    return getFormattedLocation(event)
  }
}
export const isPastEvent = (event: DetailedEvent): boolean => PAST_EVENT_STATUSES.includes(event.event.status)

export const getTitle = (event: DetailedEvent): string => event.event.title

export const hasOrder = (event: DetailedEvent): boolean => event.orders.length > 0

export const getEventOrders = (event: DetailedEvent): wix.events.ticketing.Order[] => event.orders

export const getEventRsvp = (event: DetailedEvent): wix.events.rsvp.Rsvp => event.rsvp

export const getFormattedOrderTotal = (order: wix.events.ticketing.Order): string => {
  const {
    invoice: {discount, total},
  } = order
  return getFormattedMoney(discount ? discount.afterDiscount : total)
}
