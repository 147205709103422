import {EventType, RegistrationStatus} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import {RsvpStatusOptions} from '@wix/wix-events-commons-statics/dist/types/enums/RsvpCollectionConfig'
import * as urijs from 'urijs'
import {Multilingual} from '../types/state'

export const isEventExternalRegistration = (event: wix.events.Event) => {
  return event.registration.status === RegistrationStatus.OPEN_EXTERNAL
}

export const isNoResponseEnabled = (event: wix.events.Event) =>
  event.registration.rsvpCollection.config.rsvpStatusOptions === RsvpStatusOptions.YES_AND_NO

export const getTimeZoneId = (event: wix.events.Event) =>
  (event && !isDateTbd(event) && event.scheduling.config.timeZoneId) || 'UTC'

export const isDateTbd = (event: wix.events.Event) => event.scheduling.config.scheduleTbd

export const isRsvp = (event: wix.events.Event): boolean => event.registration.type === EventType.RSVP

export const getEventUrl = (pageUrl: string = '', event: wix.events.Event, multilingual: Multilingual) => {
  const url = new urijs(pageUrl)
  const path = url.path()
  let eventUrl = url.path(`${path}/${event.slug}`)

  if (multilingual.isEnabled) {
    const lang = multilingual.currentLanguage
    eventUrl = eventUrl.addQuery({lang})
  }

  return eventUrl.toString()
}
