import {BrowserClient as SentryBrowserClient} from '@sentry/browser'
import {Dedupe} from '@sentry/integrations'
import * as React from 'react'

export interface ISentryErrorBoundaryOptions {
  dsn: string
  environment: string
}

export function withSentryErrorBoundary(ComponentToWrap, options: ISentryErrorBoundaryOptions, ErrorStateComponent?) {
  return class SentryErrorBoundary extends React.Component<any> {
    sentry: any
    state = {
      error: null,
    }

    getSentry() {
      if (!this.sentry) {
        this.sentry = new SentryBrowserClient({
          dsn: options.dsn,
          environment: options.environment,
          integrations: [new Dedupe()],
        })
      }
      return this.sentry
    }

    componentDidCatch(error) {
      if (this.state.error === null) {
        this.setState({error})
      }
      const sentry = this.getSentry()
      sentry.captureException(error)
    }

    render() {
      return this.state.error && ErrorStateComponent ? (
        <ErrorStateComponent error={this.state.error} {...this.props} />
      ) : (
        <ComponentToWrap {...this.props} />
      )
    }
  }
}
