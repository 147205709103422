import {withStyles} from '@wix/native-components-infra'
import App from '.'
import {ErrorPage} from '../../../commons/components/error-page'
import {withSentryErrorBoundary} from '../../../commons/components/hoc/sentry-error-boundary'
import {sentryConfig} from '../../utils/sentry'

export default {
  component: withSentryErrorBoundary(
    withStyles(App, {
      cssPath: ['membersViewerWidget.stylable.bundle.css', 'membersViewerWidget.css'],
    }),
    sentryConfig('events-member-page-viewer'),
    ErrorPage,
  ),
}
