import * as DateFormatter from '@wix/wix-events-commons-statics/dist/date/formatter'
import {getFormattedMoney} from '@wix/wix-events-commons-statics/dist/price/formatter'
import * as classNames from 'classnames'
import * as React from 'react'
import {Grid} from 'wix-ui-tpa/Grid'
import {OrderDetailsProps} from '.'
import {ORDER_DETAILS} from '../../config/data-hooks'
import {getFormattedOrderTotal} from '../../selectors/event'
import {Divider} from '../styled-components/divider'
import {DottedDivider} from '../styled-components/dotted-divider'
import {EventText} from '../styled-components/event-text'
import * as styles from './order-details.scss'

export const OrderDetails = ({order, t, timezone, locale}: OrderDetailsProps) => {
  const {
    orderNumber,
    created,
    invoice: {discount, items} = {
      discount: undefined,
      items: [],
    },
  } = order
  return (
    <div className={styles.root} data-hook={ORDER_DETAILS}>
      <DottedDivider />
      <div className={styles.content}>
        <Grid columnGap={20} maxColumns={2} rowGap={30} uniformRowHeight={false}>
          <Grid.Item colSpan={1} rowSpan={1}>
            <EventText>{t('order.number', {orderNumber})}</EventText>
          </Grid.Item>
          <Grid.Item colSpan={1} rowSpan={1}>
            <EventText>
              {t('order.date', {
                date: DateFormatter.getYearMonthDay(created, timezone, locale),
              })}
            </EventText>
          </Grid.Item>
          {items.map(item => (
            <React.Fragment key={item.id}>
              <Grid.Item colSpan={1} rowSpan={1}>
                <div className={styles.verticalCol}>
                  <EventText>{item.name}</EventText>
                  <div className={styles.ticketPrice}>
                    <EventText faded>
                      {t('order.ticket.price', {
                        price: getFormattedMoney(item.price),
                      })}
                    </EventText>
                  </div>
                </div>
              </Grid.Item>
              <Grid.Item colSpan={1} rowSpan={1}>
                <div className={styles.horizontalSpacing}>
                  <EventText>{t('order.item.quantity', {quantity: item.quantity})}</EventText>
                  <EventText>{getFormattedMoney(item.total)}</EventText>
                </div>
              </Grid.Item>
            </React.Fragment>
          ))}
          {discount ? (
            <>
              <Grid.Item colSpan={1} rowSpan={1}>
                <div className={styles.verticalCol}>
                  <EventText>{`${t('order.coupon.code')}${discount.code}`}</EventText>
                </div>
              </Grid.Item>
              <Grid.Item colSpan={1} rowSpan={1}>
                <div className={styles.horizontalSpacing}>
                  <EventText>{t('order.item.quantity', {quantity: 1})}</EventText>
                  <EventText>{`-${getFormattedMoney(discount.amount)}`}</EventText>
                </div>
              </Grid.Item>
            </>
          ) : null}
          <Grid.Item colSpan={1} rowSpan={1} />
          <Grid.Item colSpan={1} rowSpan={1}>
            <div className={styles.verticalCol}>
              <Divider />
              <div className={classNames(styles.horizontalSpacing, styles.total)}>
                <EventText large>{t('order.total')}</EventText>
                <EventText large>{getFormattedOrderTotal(order)}</EventText>
              </div>
            </div>
          </Grid.Item>
        </Grid>
      </div>
    </div>
  )
}
