export const APP_HEADER_TITLE = 'EVENTS_MA_PAGE.APP_HEADER_TITLE'
export const APP_HEADER_DESCRIPTION = 'EVENTS_MA_PAGE.APP_HEADER_DESCRIPTION'
export const EVENT_SUMMARY = 'EVENTS_MA_PAGE.EVENT_SUMMARY'
export const NO_EVENTS = 'EVENTS_MA_PAGE.NO_EVENTS'
export const EVENT_LIST_TABS = 'EVENTS_MA_PAGE.EVENT_LIST_TABS'

export const EVENT_TITLE = 'EVENTS_MA_PAGE.EVENT_TITLE'
export const ORDER_DETAILS = 'EVENTS_MA_PAGE.ORDER_DETAILS'

export const EXPANDABLE_ROW = 'EVENTS_MA_PAGE.EXPANDABLE_ROW'
export const EXPANDABLE_ROW_ICON = 'EVENTS_MA_PAGE.EXPANDABLE_ROW_ICON'

export const START_DATE = 'EVENTS_MA_PAGE.START_DATE'
export const FULL_LOCATION = 'EVENTS_MA_PAGE.FULL_LOCATION'

export const BUTTON_GO_TO_DETAILS = 'EVENTS_MA_PAGE.BUTTON_GO_TO_DETAILS'
export const BUTTON_CANCEL_RSVP = 'EVENTS_MA_PAGE.BUTTON_CANCEL_RSVP'
export const BUTTON_SHARE = 'EVENTS_MA_PAGE.BUTTON_SHARE'
export const BUTTON_SHOW_ORDERS = 'EVENTS_MA_PAGE.BUTTON_SHOW_ORDERS'
export const BUTTON_DOWNLOAD_TICKETS = 'EVENTS_MA_PAGE.BUTTON_DOWNLOAD_TICKETS'
export const BUTTON_GO_TO_EVENTS = 'EVENTS_MA_PAGE.BUTTON_GO_TO_EVENTS'
export const BACK_BUTTON = 'EVENTS_MA_PAGE.BACK_BUTTON'

export const MOBILE_ORDER_NUMBER = 'EVENTS_MA_PAGE.MOBILE_ORDER_NUMBER'
export const MOBILE_ORDER_DATE = 'EVENTS_MA_PAGE.MOBILE_ORDER_DATE'
export const MOBILE_ORDER_TOTAL = 'EVENTS_MA_PAGE.MOBILE_ORDER_TOTAL'
export const MOBILE_ORDER_DETAILS_NUMBER = 'EVENTS_MA_PAGE.MOBILE_ORDER_DETAILS_NUMBER'
export const MOBILE_ORDER_DETAILS_TOTAL = 'EVENTS_MA_PAGE.MOBILE_ORDER_DETAILS_TOTAL'
export const MOBILE_BUTTON_DOWNLOAD_TICKETS = 'EVENTS_MA_PAGE.MOBILE_BUTTON_DOWNLOAD_TICKETS'
export const TICKET = 'EVENTS_MA_PAGE.TICKET'
export const TICKET_TOTAL = 'EVENTS_MA_PAGE.TICKET_TOTAL'
export const DISCOUNT_TOTAL = 'EVENTS_MA_PAGE.DISCOUNT_TOTAL'
