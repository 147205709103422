import * as i18next from 'i18next'
import * as memoize from 'lodash.memoize'

export const i18nInstance = i18next.createInstance()

const importResource = (resource, language) => {
  return import(/* webpackChunkName: "[request]" */ `../../assets/locale/${resource}_${language}.json`)
}

const importResources = (resources, language) => {
  return Promise.all(resources.map(resource => importResource(resource, language))).then(translations =>
    translations.reduce((ret: object, translation: object) => ({...ret, ...translation}), {}),
  )
}

export default memoize(function i18n(locale: string, resources: string | string[]) {
  return i18nInstance
    .use({
      type: 'backend',
      read: async (language, namespace, callback) => {
        // We configure how i18next should fetch a translation resource when it
        // needs it: We use Webpack's dynamic imports to fetch resources without
        // increasing our bundle size.
        //
        // See https://webpack.js.org/guides/code-splitting/#dynamic-imports for
        // more information.
        return (Array.isArray(resources) ? importResources(resources, language) : importResource(resources, language))
          .then(translation => callback(null, translation))
          .catch(error => callback(error))
      },
    } as any)
    .init({
      // Initial language
      lng: locale,

      // Fallback language
      fallbackLng: 'en',

      // Don't use a key separator (no support for nested translation objects)
      keySeparator: false,

      // Wait for translation data to be available before rendering a component
      react: {
        wait: true,
      },
    })
})
