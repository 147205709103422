import * as React from 'react'
import {BUTTON_GO_TO_EVENTS, NO_EVENTS} from '../../config/data-hooks'
import {Divider} from '../styled-components/divider'
import {NoEventsLink} from '../styled-components/no-events-link'
import {NoEventsText} from '../styled-components/no-events-text'
import {NoEventsProps} from './index'
import * as s from './no-events.scss'

export const NoEvents = ({url, navigate, mobile, cta, t}: NoEventsProps) => (
  <div data-hook={NO_EVENTS} className={mobile ? s.mobile : null}>
    <div className={s.content}>
      <NoEventsText mobile={mobile}>{t('members-page.no-events.message')}</NoEventsText>
      <div className={s.button}>
        <NoEventsLink
          mobile={mobile}
          as="a"
          href={url}
          onClick={!url && navigate ? () => navigate() : null}
          data-hook={BUTTON_GO_TO_EVENTS}
        >
          {cta}
        </NoEventsLink>
      </div>
    </div>
    <Divider />
  </div>
)
