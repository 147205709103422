import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {isEventExternalRegistration} from '../selectors/event'

export const navigateToDetailsPage = async (
  event: wix.events.Event,
  isDetailsPageEnabled: boolean,
  wixCodeApi: IWixAPI,
) => {
  if (!isEventExternalRegistration(event)) {
    const {query} = wixCodeApi.location
    const queryString = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&')
    const target = `${event.slug}${isDetailsPageEnabled ? '' : '/form'}${queryString ? `?${queryString}` : ''}`
    const url = await wixCodeApi.site.getSectionUrl({sectionId: 'events'})
    if (wixCodeApi.location.to && url.relativeUrl) {
      const path = `${url.relativeUrl || url.url}/${target}`
      wixCodeApi.location.to(path)
    } else {
      wixCodeApi.location.navigateToSection({sectionId: 'events', state: target, queryParams: ''})
    }
  }
}

export const getPage = async (wixCodeApi: IWixAPI, pageId: string) => {
  const {pages} = await wixCodeApi.site.getSiteStructure()
  return pages.find(page => page.id === pageId)
}

export const getHomePage = async (wixCodeApi: IWixAPI) => {
  const {pages} = await wixCodeApi.site.getSiteStructure()
  return pages.find(page => page.isHomePage)
}
